export const myMessage = (state) => {
  return state.message;
};

export const getJobsByTerm =
  (state) =>
  (term = "") => {
    if (term.length === 0) return state.jobs;
    return state.jobs.filter((job) =>
      job.job_title.toLowerCase().includes(term.toLowerCase())
    );
  };
