export const isAuthenticated = (state) => {
  return state.isAuthenticated;
};

export const nameUser = (state) => {
  return state.user;
};

export const tokenUser = (state) => {
  return state.token;
};
