import { createStore } from "vuex";
import auth from "@/modules/auth/store";
import dashboard from "@/modules/adminPanel/store";

const store = createStore({
  modules: {
    auth,
    dashboard,
  },
});

export default store;
