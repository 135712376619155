import { createRouter, createWebHistory } from "vue-router";

import login from "@/modules/auth/router";
import dashboard from "@/modules/adminPanel/router";
import store from "@/store";

const routes = [
  {
    path: "/",
    ...dashboard,
  },
  {
    path: "/login",
    ...login,
  },
  {
    path: "/:pathMach(.*)*",
    component: () =>
      import(/* webpackChunkName: "NotPageFound" */ "@/views/NotPageFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const protectedRoute = to.matched.some((record) => record.meta.requireAuth);

  const requireAdmin = to.matched.some((record) => record.meta?.requireAdmin);

  if (Number(store.state.auth.user.role) != 1 && requireAdmin)
    next({ name: "listDownload" });

  protectedRoute && store.state.auth.token === null
    ? next({ name: "login" })
    : next();
});

export default router;
