export const isAuthenticated = (state, value) => {
  state.isAuthenticated = value;
};

export const userLogin = (state, { user_name, user_rol }) => {
  state.user = {
    name: user_name,
    role: user_rol,
  };
};

export const setToken = (state, value) => {
  state.token = value;
};

export const logout = (state) => {
  localStorage.removeItem("token");
  location.reload();

  state.token = null;
  state.user = null;
  state.isAuthenticated = null;
};
