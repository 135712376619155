export default {
  name: "dashboard",
  meta: { requireAuth: true },
  component: () =>
    import(/* webpackChunkName: "dashboard" */ "../layouts/DashboardLayout"),
  children: [
    {
      path: "/dashboard",
      name: "home",
      meta: { requireAdmin: 1 },
      component: () =>
        import(
          /* webpackChunkName: "DashboardPage" */ "../pages/controlDownload/DashboardPage"
        ),
    },
    {
      path: "/listDownload",
      name: "listDownload",
      meta: { requireAdmin: 0 },
      component: () =>
        import(
          /* webpackChunkName: "ListDownloadPage" */ "../pages/controlDownload/ListDownloaPage.vue"
        ),
    },
    {
      path: "/importCsv",
      name: "importCsv",
      meta: { requireAdmin: 1 },
      component: () =>
        import(
          /* webpackChunkName: "ImportCsvPage" */ "../pages/controlDownload/ImportCsvPage.vue"
        ),
    },
    {
      path: "/listJobs/:id",
      name: "listJobs",
      component: () =>
        import(
          /* webpackChunkName: "ImportCsvPage" */ "@/modules/adminPanel/pages/reviewData/ListJobs.vue"
        ),
      meta: { requireAdmin: 0 },
      props: (router) => {
        return Number(router.params.id);
      },
    },
    {
      path: "/usersList",
      name: "usersList",
      component: () =>
        import(
          /* webpackChunkName: "usersList" */ "../pages/users/UsersList.vue"
        ),
      meta: { requireAdmin: 1 },
    },
    {
      path: "",
      redirect: { name: "home" },
    },
  ],
};
