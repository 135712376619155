import loginApi from "../helpers/login";

export const login = async ({ commit }, userData) => {
  try {
    const { data } = await loginApi(userData);
    if (data.status != 200) return data;

    const { token, user_name, user_rol } = data.response;

    commit("userLogin", { user_name, user_rol });
    commit("setToken", token);

    localStorage.setItem("token", token);

    const userLogin = { user_name, user_rol };

    localStorage.setItem("userLogin", JSON.stringify(userLogin));

    return { ok: true };
  } catch (error) {
    return { message: "api_error" };
  }
};

export const getToken = ({ commit }) => {
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

  commit("setToken", token);

  const user = localStorage.getItem("userLogin")
    ? localStorage.getItem("userLogin")
    : null;

  if (user) commit("userLogin", JSON.parse(user));
};

export const logout = ({ commit }) => {
  commit("logout");
};
