import disqavaApi from "@/api/disqavaApi";

const loginApi = async ({ email, password }) => {
  const headers = { "Content-Type": "application/x-www-form-urlencoded" };
  return await disqavaApi.post(
    "/index.php?route=login",
    { email, password },
    { headers }
  );
};

export default loginApi;
