export const clearMessage = (state) => {
  state.message = null;
};

export const updateMessage = (state, message) => {
  state.message = message;
};

export const updateJobs = (state, jobs) => {
  state.jobs = jobs;
};

export const updateCategories = (state, categories) => {
  state.categories = categories;
};
